@import "../../variables/variables.less";

@media @tablet, @desktop, @desktop-large {
    
    .hmct-floating-el {
        display: none;
        width: 387px;
        height: 121px;
        box-shadow: 0 -5px 25px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        position: fixed;
        bottom: 0;
        right: 100px;
        border-radius: 3px;
        border-top: solid 5px transparent;
        background-image: linear-gradient(white, white),linear-gradient(91deg, #ffb600, #ffb910);
        background-origin: border-box;
        background-clip: content-box, border-box;
        z-index: 100;
        animation: fadein 0.6s;
        -webkit-animation: fadein 0.6s; 
        -moz-animation: fadein 0.6s; 
        -ms-animation: fadein 0.6s; 
        -o-animation: fadein 0.6s; 

        @keyframes fadein {
            from { opacity: 0; transform: translateY(100%);}
            to   { opacity: 1; }
        }
            
        @-moz-keyframes fadein {
            from { opacity: 0; transform: translateY(100%);}
            to   { opacity: 1; }
        }
            
        @-webkit-keyframes fadein {
            from { opacity: 0; transform: translateY(100%);}
            to   { opacity: 1; }
        }
            
        @-ms-keyframes fadein {
            from { opacity: 0; transform: translateY(100%);}
            to   { opacity: 1; }
        }
            
        @-o-keyframes fadein {
            from { opacity: 0; transform: translateY(100%);}
            to   { opacity: 1; }
        }

        .floating-close {
            position: absolute;
            right: 0;
            margin: 5px 5px 0 0;
            cursor: pointer;
        }

        .box-content {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            margin: 0 10px;
            p {
                margin-bottom: 0;
            }
        }

        .help {
            font-size: 20px;
            font-family: "AVGSans_Light";
            letter-spacing: -0.6px;
            line-height: 26px;
            font-weight: normal;
            width: 218px;
            color: #000000;
            text-align: left;
        }

        .button {
            border: 1px solid #40acff;
            border-radius: 16px;
            color: #40acff;
            font-size: 16px;
            font-family: Verdana;
            padding: 6px 16px 6px 16px;
            text-decoration: none;
            margin-bottom: 0;
            margin-left: 16px;
            min-width: 118px;            
        }

        &.hmct-show {
            display: flex;
            justify-content: center;

            &.hero-overflow {
                display: none;
            }
        }
    }

    .hmct-floating-el-collapsed {
        box-shadow: 0 -5px 25px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        bottom: 0;
        border-radius: 3px;
        border-top: solid 5px transparent;
        background-image: linear-gradient(white, white),linear-gradient(91deg, #ffb600, #ffb910);
        background-origin: border-box;
        background-clip: content-box, border-box;
        cursor: pointer;
        height: 69px;
        position: fixed;
        right: 100px;
        visibility: hidden;
        width: 64px;

        .icon {
            font-size: 32px;
            padding: 16px 18px;
            img {
                vertical-align: initial;
            }
        }

        &.hero-overflow {
            display: none;
        }
    }

    .collapse {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }
    .collapse.in {
        opacity: 1;
    }

    .expand {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }
}

@media @mobile {
    .hmct-floating-el, .hmct-floating-el-collapsed {
        display: none;
    }
}

.ja-jp {
    .hmct-floating-el {
        .button {
            max-width: 128px;
            margin-left: 10px;
            margin-top: 10px;
        }
    }
}

.lng-pt {
    .hmct-floating-el {
        .button {
            padding: 6px 10px 6px 10px !important;
            margin-left: 10px !important;
        }
    }
}